<template>
  <svg class="icon icon--trash-bin" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2.5H8l-1 .8H4.3V5h11.6V3.3H13l-.8-.8zm1.3 5v8.3H6.7V7.5h6.6zM5 5.8h10v10c0 1-.8 1.7-1.7 1.7H6.7c-1 0-1.7-.8-1.7-1.7v-10z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VTrashBin'
}
</script>
