<template>
  <!-- eslint-disable vue/max-len -->
  <svg class="icon icon--activate" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.117 6.358l-1.159-1.4A1.21 1.21 0 0017 4.5H7c-.392 0-.733.175-.967.458l-1.15 1.4A1.632 1.632 0 004.5 7.417v10.416c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V7.417c0-.4-.142-.775-.383-1.059zM7.2 6.167h9.6l.675.808H6.533l.667-.808zM6.167 17.833V8.667h11.666v9.166H6.167zm4.625-1.666h2.416v-2.5h2.125L12 10.333l-3.333 3.334h2.125v2.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VActivate'
}
</script>
