<template>
  <svg class="icon icon--archive" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 3L17 4.4c.3.2.4.6.4 1v10.4c0 1-.8 1.7-1.7 1.7H4.2c-1 0-1.7-.8-1.7-1.7V5.4c0-.4.1-.8.4-1L4 3c.3-.3.6-.5 1-.5h10c.4 0 .7.2 1 .5zm-1.2 1.2H5.2l-.7.8h11l-.7-.8zM4.2 15.8V6.7h11.6v9.1H4.2zm4.6-7.5h2.4v2.5h2.1L10 14.2l-3.3-3.4h2V8.3z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconArchive'
}
</script>
